import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { addToCart, modifyItemCount } from '../../actions/carts'
import { delAddress } from '../../actions/address'
import event from '../../utils/event'
import Fetch from '../../utils/fetch-config'
import api from '../../utils/api'
import { loadOptions } from '../../utils/common-api'
import { copyData, hasItem, getItemIndex, getAllUrlParam, getSourceArrayField } from '../../utils/common'
import { commentMap } from '../../utils/mapping-table'
import { Carousel, Toast, List } from 'antd-mobile'
import { get as getGlobalData, set as setGlobalData } from '../../utils/global-data'
import { useIntegral, integral2money, INVITE_ID } from '../../constants/global'
import FakeNews from '../../components/fake-news'
import FabCart from '../../components/fab-cart'
import FabBackTop from '../../components/fab-back-top'
import CartPopup from '../../components/cart-popup'
import ProductSection from '../../components/product-section'
import SkeletonProductSection from '../../components/skeleton/product-section'
import CommentItem from '../../components/comment-item'
import AtLoadMore from '../../components/load-more'
import PreviewMask from '../../components/preview-mask'
import fallsDecorator from '../../hocs/fallsDecorator'
import backTopDecorator from '../../hocs/backTopDecorator'
import entryDecorator from '../../hocs/entryDecorator'

import './detail.scss'
import styles from './detail.module.scss'
import share from '../../images/icons/share.png'
import shop from '../../images/icons/shop.png'
import kefu from '../../images/icons/kefu.png'

const CURRENT_CART = 'current_cart';
const LIST_CART = 'list_cart';
const COMMENT_COUNT = 1;

let _tx = 0, _ty = 0;
let startTimer = null, endTimer = null;
let clickSource = CURRENT_CART;
let isOnBuy = false;    // 立即购买

function resetCartVars() {
    _tx = 0;
    _ty = 0;
}

const mapStateToProps = store => ({
    carts: store.carts,
    userInfo: store.userInfo
});
const mapDispatchToProps = dispatch => ({
    addToCart: item => {
        dispatch(addToCart(item));
    },
    modifyItemCount: (index, stepperVal) => {
        dispatch(modifyItemCount(index, stepperVal));
    },
    delAddress: () => {
        dispatch(delAddress());
    },
});
class ProductDetail extends React.Component {
    state = {
        activeSwiperIndex: 0,
        isLikeOn: false,
        cartAnimationModel: {
            tx: 0,
            ty: 0
        },
        startAddToCartAnimation: false,
        showCartPopup: false,
        addItem: {},
        detailItem: {
            GoodsImage: [],
            GoodsRemarkImage: [],
            MinPrice: 0
        },
        fakeNews: [],
        commentObject: {
            CommentList: []
        },
        likes: [],
        goodsId: '',
        activeType: '',
        activeUrl: '',
    }
    componentDidMount() {
        this.props.delAddress();

        isOnBuy = false;
        this.fabCart = React.createRef();
        const { goodsId } = this.props.match.params;
        const params = getAllUrlParam(this.props.location.search.slice(1));
        const memberId = params.hasOwnProperty('memberId') && params.memberId;
        const inviteId = params.hasOwnProperty('inviteId') && params.inviteId;
        if (inviteId) {
            setGlobalData(INVITE_ID, inviteId);
        }
        this.setState({
            goodsId
        });

        this.loadProductDetail(goodsId, memberId);
        this.loadComment(goodsId);
        this.loadFakeNews(goodsId);
    }
    componentWillReceiveProps(nextProps) {
        let currGoodsId = this.props.match.params.goodsId;
        let nextGoodsId = nextProps.match.params.goodsId;
        if (currGoodsId !== nextGoodsId) {
            this.loadProductDetail(nextGoodsId);
        }
    }
    componentWillUnmount() {
        event.removeAllListeners();
        this.postCommentLike();
    }
    async loadProductDetail(goodsId, memberId) {
        console.log(this.props.userInfo.Id, 'userId')
        let res = await Fetch.get(api.getGoodsDetail + `?GoodsId=${goodsId}&MemberId=${memberId || this.props.userInfo.Id}`);
        this.setState({
            detailItem: res.data
        });
        console.log(res.data.Id, 'res.data.Id')
        event.emit('init', api.getGoodsLikeList + `?GoodsId=${res.data.Id}&MemberId=${memberId || this.props.userInfo.Id}`);
    }
    async loadComment(goodsId) {
        let res = await Fetch.get(api.getGoodsCommentList + `?GoodsId=${goodsId}&Status=${commentMap.all}&PageIndex=1`);
        this.setState({
            commentObject: res.data
        });
    }
    postCommentLike() {
        const { likes } = this.state;
        for (let likeCommentId of likes) {
            Fetch.post(api.createGoodsCommentLikes, { CommentId: likeCommentId });
        }
    }
    onLike (item, isLikeOn) {
        const { likes } = this.state;
        let _likes = [...likes];
        console.log(item.Id, 'onLike ===== item.Id')
        let isInclude = _likes.includes(item.Id);

        if (isLikeOn) {
            if (!isInclude) {
                _likes.push(item.Id);
            }
        } else {
            if (isInclude) {
                _likes.splice( _likes.findIndex(el => el.Id === item.Id), 1);
            }
        }
        this.setState({
            likes: _likes
        })
    }
    async loadFakeNews(goodsId) {
        let res = await Fetch.get(api.getGoodsBuyRateList + `?GoodsId=${goodsId}`);
        this.setState({
            fakeNews: res.data
        });
    }
    onPreview(url, type) {
        let activeType = type === 1
            ? "image"
            : type === 2
            ? "video"
            : "image";

        this.setState({
            activeType,
            activeUrl: url,
        });
    }
    onClosePreviewMask() {
        this.setState({
            activeUrl: '',
        });
    }
    onLinkCommentList() {
        this.props.history.push({ pathname: '/commentList', query: { goodsId: this.state.goodsId } })
    }
    onAfterChange(index) {
        this.setState({ activeSwiperIndex: index });
    }
    onProductItem(item) {
        console.log(item.Id, 'onProductItem ===== item.Id')
        this.props.history.replace(`/productDetail/${item.Id}`);
    }
    async onCart(item, cartRect) {
        let fabCartRect = this.fabCart.current.getBoundingClientRect();
        let offsetRight = (fabCartRect.right - cartRect.right) - cartRect.width / 2;
        let offsetBottom = (fabCartRect.bottom - cartRect.bottom) - cartRect.height / 2;

        _tx = offsetRight;
        _ty = offsetBottom;

        clickSource = LIST_CART;

        let _addItem = await loadOptions(item);

        this.setState({
            showCartPopup: true,
            addItem: _addItem
        });
    }
    async onCurrentCart(item) {
        clickSource = CURRENT_CART;

        let _addItem = await loadOptions(item);

        this.setState({
            showCartPopup: true,
            addItem: _addItem
        });
    }
    async onBuy(item) {
        clickSource = CURRENT_CART;
        isOnBuy = true;

        let _addItem = await loadOptions(item);

        this.setState({
            showCartPopup: true,
            addItem: _addItem
        });
    }
    updateCart() {
        const { addItem } = this.state;
        let result = hasItem(this.props.carts, addItem);
        if (!result) {
            this.props.addToCart(addItem);
        } else {
            let modifyItemIndex = getItemIndex(this.props.carts, addItem);
            let modifyStepperVal = addItem.stepperVal + this.props.carts[modifyItemIndex].stepperVal;
            this.props.modifyItemCount(modifyItemIndex, modifyStepperVal);
        }
    }
    onCartProductImgTransitionEnd() {
        this.setState((state) => ({
            cartAnimationModel: {
                ...state.cartAnimationModel,
                tx: 0,
                ty: 0
            },
            startAddToCartAnimation: false
        }));
        this.updateCart();
    }
    closeCartPopup() {
        resetCartVars();
        this.setState({ showCartPopup: false });
    }
    async resetAddItemForList(item) {
        let _obj = await loadOptions(item);
        const originGoodsImageUrl = getSourceArrayField(this.props.fallsData.falls, item, 'GoodsImageUrl');

        let _addItem = copyData(item);
        _addItem.GoodsImageUrl = originGoodsImageUrl;
        _addItem.GoodsNum = _obj.GoodsNum;
        _addItem.GoodsPrice = null;

        this.setState({
            addItem: _addItem
        });
    }
    async resetAddItemForCurrent(item) {
        let _obj = await loadOptions(item);

        let _addItem = copyData(item);
        _addItem.GoodsImageUrl = this.state.detailItem.GoodsImageUrl;
        _addItem.GoodsNum = _obj.GoodsNum;
        _addItem.GoodsPrice = null;

        this.setState({
            addItem: _addItem
        });
    }
    async onOptionTag(optionsTag) {
        let _addItem;
        if (optionsTag.length === this.state.addItem.GoodsGroup.length) {
            let res = await Fetch.get(api.getGoodsSpecDetailGroupInfo + `?GoodsGroup=${JSON.stringify(optionsTag)}`);
            _addItem = {
                ...this.state.addItem,
                ...res.data,
                GoodsImageUrl:
                    res.data.GoodsImageUrl
                        ? res.data.GoodsImageUrl
                        : clickSource === CURRENT_CART
                        ? this.state.detailItem.GoodsImageUrl
                        : getSourceArrayField(this.props.fallsData.falls, this.state.addItem, 'GoodsImageUrl')
            };
            this.setState({
                addItem: _addItem
            });
        } else {
            clickSource === CURRENT_CART
            ? this.resetAddItemForCurrent(this.state.addItem)
            : this.resetAddItemForList(this.state.addItem);
        }
    }
    onConfirm(optionsTag, stepperVal) {
        if (startTimer) {
            clearTimeout(startTimer);
        }
        if (endTimer) {
            clearTimeout(endTimer);
        }
        if (optionsTag.length !== this.state.addItem.GoodsGroup.length) {
            Toast.info('请选择完整规格', 1);
        } else {
            console.log(optionsTag);
            const { addItem } = this.state;
            let _addItem = copyData(addItem);
            _addItem.stepperVal = Math.min(_addItem.GoodsNum, stepperVal);
            _addItem.optionsTag = optionsTag;
            this.setState({
                showCartPopup: false,
                addItem: _addItem
            }, () => {
                if (clickSource === LIST_CART) {
                    startTimer = setTimeout(() => {
                        this.setState({ startAddToCartAnimation: true });
                    }, 450);
                    endTimer = setTimeout(() => {
                        this.setState((state) => ({
                            cartAnimationModel: {
                                ...state.cartAnimationModel,
                                tx: _tx,
                                ty: _ty
                            }
                        }));
                        resetCartVars();
                    }, 500);
                } else {
                    if (isOnBuy) {
                        isOnBuy = false;
                        this.props.history.push({pathname: '/orderBill', query: { billCarts: [this.state.addItem] }});
                    } else {
                        Toast.success('加入购物车成功', 1);
                        this.updateCart();
                    }
                }
            });
        }
    }
    onFabBackTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    onLinkIntegralDeduct() {
        this.props.history.push({ pathname: '/integralDeduct', query: { maxIntegral: this.state.detailItem.MaxUseIntergralCount } });
    }
    onShare() {
        this.props.history.push({ pathname: '/productPoster', query: { item: this.state.detailItem } });
    }
    renderCarousel() {
        const { activeSwiperIndex, detailItem, showCartPopup } = this.state;

        return (
            <div className={styles['detail-swiper']}>
                <Carousel
                    className='detail-carousel'
                    dots={false}
                    afterChange={this.onAfterChange.bind(this)}>
                    {
                        (detailItem.GoodsImage && detailItem.GoodsImage.length > 0) &&
                        detailItem.GoodsImage.map((item, index) => {
                            return (
                                <React.Fragment key={`detail-swiper-${index}`}>
                                    {
                                        item.ImageType === 1
                                        ? (
                                            <div
                                                className={styles['detail-swiper-banner']}
                                                style={{
                                                    backgroundImage: `url(${item.GoodsImageUrl})`
                                                }}>

                                            </div>
                                        )
                                        : (
                                                <div
                                                    key={`detail-swiper-${index}`}
                                                    className={styles['detail-swiper-banner']}>
                                                    <video
                                                        className={styles['detail-swiper-video']}
                                                        src={item.GoodsImageUrl}
                                                        controls='controls'
                                                        poster={item.VideoCoverUrl}
                                                        preload='auto'
                                                        playsinline
                                                        muted
                                                        autoPlay
                                                        style={{display: showCartPopup ? 'none' : 'block'}}>
                                                        您的浏览器不支持 video 标签。
                                                    </video>
                                                </div>
                                            )
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </Carousel>
                {detailItem.GoodsImage && (<div className={styles['detail-swiper-count-box']}>
                    {activeSwiperIndex + 1}
                    /
                    {detailItem.GoodsImage.length}
                </div>)}
            </div>
        )
    }
    renderFakeNews() {
        return (
            <FakeNews list={this.state.fakeNews} />
        )
    }
    renderDiscountBox() {
        const { detailItem } = this.state;
        const extraText = getGlobalData(useIntegral)
            ? `使用${getGlobalData(useIntegral)}积分，抵扣￥${getGlobalData(integral2money)}`
            : `${detailItem.MaxUseIntergralCount}积分可用`;

        return (
            <div className={styles['discount-box']}>
                <List className='discount-list'>
                    {
                        detailItem.UseIntegral && (
                            <List.Item
                                className='discount-list-item'
                                extra={extraText}
                                arrow='horizontal'
                                onClick={this.onLinkIntegralDeduct.bind(this)}>
                                积分
                            </List.Item>
                        )
                    }
                </List>
            </div>
        )
    }
    renderMainInfoBox() {
        const { detailItem } = this.state;

        return (
            <div className={styles['main-info-box']}>
                <div className={styles['info-top']}>
                    <p className={styles['info-price']}>
                        <span className={styles['info-price-yuan']}>￥</span>
                        {detailItem.MinPrice}
                        <span className={styles['info-price-qi']}> 起</span>
                    </p>
                    <div className={styles['info-share-wrapper']} onClick={this.onShare.bind(this)}>
                        <img className={styles['info-share-img']} src={share} alt=""/>
                        <p className={styles['info-share-text']}>分享</p>
                    </div>
                </div>
                <div className={styles['info-center']}>
                    {detailItem.GoodsName}
                </div>
                <div className={styles['info-bottom']}>
                    {detailItem.GoodsDetail}
                </div>
            </div>
        )
    }
    renderCommentAvatarGroup() {
        const { commentObject } = this.state;
        let _commentList = commentObject.CommentList.slice(0,3);

        return (
            <div className={styles['avatar-box']}>
                {
                    _commentList.map((item, index) => {
                        return (
                            <div
                                key={`avatar-list-item-${index}`}
                                className={styles['avatar']}
                                style={{backgroundImage: `url(${item.AvatarUrl})`}}>

                            </div>
                        )
                    })
                }
            </div>
        )
    }
    renderCommentList() {
        const { commentObject } = this.state;
        let _commentList = commentObject.CommentList.slice(0, COMMENT_COUNT);

        return (
            <div className={styles['comment-list']}>
                {
                    _commentList.map((item, index) => {
                        return (
                            <CommentItem
                                key={`comment-list-${index}`}
                                item={item}
                                onLike={this.onLike.bind(this)}
                                onPreview={this.onPreview.bind(this)} />
                        )
                    })
                }
            </div>
        )
    }
    renderCommentBox() {
        const { commentObject } = this.state;

        return (
            <div className={styles['comment-box']}>
                <div className={styles['comment-top']} onClick={this.onLinkCommentList.bind(this)}>
                    <div className={styles['comment-top-left']}>
                        <p className={styles['comment-top-title']}>共{commentObject.CommentCount}条评论</p>
                        {this.renderCommentAvatarGroup()}
                    </div>
                    <div className={styles['comment-top-right']}>
                        看看TA们都说了什么
                    </div>
                </div>
                {this.renderCommentList()}
                {
                    commentObject.CommentCount > COMMENT_COUNT && (
                        <div className={styles['comment-bottom']} onClick={this.onLinkCommentList.bind(this)}>
                            查看全部{commentObject.CommentCount}条评论
                        </div>
                    )
                }
            </div>
        )
    }
    renderIntroduction() {
        const { detailItem } = this.state;

        return (
            <div className={styles['introduction-box']} style={{display: (detailItem.GoodsRemarkImage && detailItem.GoodsRemarkImage.length > 0) ? 'block' : 'none'}}>
                <div className={styles['introduction-title']}>商品介绍</div>
                <div className={styles['introduction-img-box']}>
                    {
                        (detailItem.GoodsRemarkImage && detailItem.GoodsRemarkImage.length > 0) &&
                        detailItem.GoodsRemarkImage.map((item, index) => {
                            return (
                                <img
                                    key={`introduction-img-${index}`}
                                    className={styles['introduction-img']}
                                    src={item.GoodsImageUrl}
                                    alt="" />
                            )
                        })
                    }
                </div>
            </div>
        )
    }
    renderRecommendBox() {
        const { fallsData } = this.props;
        const { cartAnimationModel, startAddToCartAnimation } = this.state;

        return (
            <div className='recommend-box'>
                <div className='recommend-title'>你可能还喜欢</div>
                { fallsData.falls.length > 0
                    ? <ProductSection
                        list={fallsData.falls}
                        startAnimation={startAddToCartAnimation}
                        cartAnimationModel={cartAnimationModel}
                        onProductItem={this.onProductItem.bind(this)}
                        onCart={this.onCart.bind(this)}
                        onCartProductImgTransitionEnd={this.onCartProductImgTransitionEnd.bind(this)} />
                    : <SkeletonProductSection />
                }
            </div>
        )
    }
    renderFAB() {
        const { backTopData, carts } = this.props;
        const cartsCount = carts
            .map(el => el.stepperVal || 1)
            .reduce((acc, cur) => acc + cur, 0);

        return (
            <div className={styles['fab-groups']}>
                <FabCart
                    fabCartRef={this.fabCart}
                    count={cartsCount} />
                <FabBackTop
                    show={backTopData.showBackTop}
                    onClick={this.onFabBackTop.bind(this)} />
            </div>
        )
    }
    renderCartPopup() {
        const { showCartPopup, addItem } = this.state;

        return (
            <CartPopup
                show={showCartPopup}
                item={addItem}
                onConfirm={this.onConfirm.bind(this)}
                onClose={this.closeCartPopup.bind(this)}
                onOptionTag={this.onOptionTag.bind(this)} />
        )
    }
    renderBottomLeftBox() {
        const { detailItem } = this.state;

        return (
            <div className={styles['bottom-left-box']}>
                <Link className={styles['bottom-left-feature-box']} replace to='/'>
                    <img className={styles['bottom-left-img']} src={shop} alt=""/>
                    <p className={styles['bottom-left-text']}>返回商城</p>
                </Link>
                <a
                    href={`tel:${detailItem.ServerPhone}`}
                    className={styles['bottom-left-feature-box']}>
                    <img className={styles['bottom-left-img']} src={kefu} alt=""/>
                    <p className={styles['bottom-left-text']}>客服</p>
                </a>
            </div>
        )
    }
    renderBottomBox() {
        const { detailItem } = this.state;

        return (
            <div className={styles['bottom-box--blank']}>
                <div className={styles['bottom-box']}>
                    {this.renderBottomLeftBox()}
                    <div className={styles['bottom-right-box']}>
                        <button
                            className={classNames(styles['bottom-feature-button'], styles['add-to-cart-button'])}
                            onClick={this.onCurrentCart.bind(this, detailItem)}>
                            加入购物车
                        </button>
                        <button
                            className={classNames(styles['bottom-feature-button'], styles['buy-button'])}
                            onClick={this.onBuy.bind(this, detailItem)}>立即购买</button>
                    </div>
                </div>
            </div>
        )
    }
    renderLoadMore() {
        const { fallsExtra } = this.props;

        return (
            <div className='load-more-wrapper'>
                <AtLoadMore status={fallsExtra.loadMoreStatus} />
            </div>
        )
    }
    renderPreviewMask() {
        const { activeType, activeUrl } = this.state;

        return (
            <PreviewMask
                dataType={activeType}
                dataUrl={activeUrl}
                onClose={this.onClosePreviewMask.bind(this)} />
        )
    }
    render() {
        const { commentObject } = this.state;

        return (
            <>
                {this.renderCarousel()}
                {this.state.fakeNews.length > 0 && this.renderFakeNews()}
                {this.renderMainInfoBox()}
                {this.renderDiscountBox()}
                { commentObject.CommentCount != 0 && this.renderCommentBox()}
                {this.renderIntroduction()}
                {this.renderRecommendBox()}
                {this.renderFAB()}
                {this.renderCartPopup()}
                {this.renderLoadMore()}
                {this.renderBottomBox()}
                {this.renderPreviewMask()}
            </>
        )
    }
}

ProductDetail = connect(mapStateToProps, mapDispatchToProps)(ProductDetail) || ProductDetail;
ProductDetail = entryDecorator(ProductDetail) || ProductDetail;
ProductDetail = fallsDecorator(ProductDetail) || ProductDetail;
ProductDetail = backTopDecorator(ProductDetail) || ProductDetail;
export default ProductDetail